import { Button, CircularProgress, Snackbar } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { Input } from '@material-ui/core';
import { addDays, format, parse } from 'date-fns';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { emails } from '../../cms/emails';
import { Base64 } from '../../util/base64';
import { config } from '../../util/config';
import { sendMail } from '../../util/functions';
import { useLocalStorage } from '../../util/localStorage';
import { Dots } from '../Dots';

export const Dashboard = ({ basic, store }: { basic: string; store: any }) => {
  const [stats, setStats] = useState<any>(null);

  const refresh = async () => {
    if (basic) {
      const rs = await fetch(`${config.firebase.functions}/getOrders`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Expressions-Auth': basic,
        },
        body: JSON.stringify({}),
      });

      const orderIds: string[] = [];

      const _orders = (await rs.json())?.filter(
        (o: any) => !o.refunded && o.status !== 'pending'
      );

      const items: any = {};

      for (const o of _orders) {
        if (o.status === 'confirmed') {
          if (!orderIds.includes(o.id)) {
            orderIds.push(o.id);
          }
          if (!items[o.description]) {
            items[o.description] = o.qty;
          } else {
            items[o.description] += o.qty;
          }
        }
      }

      setStats({
        orderCount: orderIds.length,
        productCount: _orders.length
          ? _orders
              .map((o: any) => o.qty)
              .reduce((a: number, b: number) => a + b)
          : 0,
        gross: _orders.length
          ? _orders
              .map((o: any) => o.gross)
              .reduce((a: number, b: number) => a + b)
          : 0,
        credit: _orders.length
          ? _orders
              .map((o: any) => o.credit)
              .reduce((a: number, b: number) => a + b)
          : 0,
        nett: _orders.length
          ? _orders
              .map((o: any) => o.total)
              .reduce((a: number, b: number) => a + b)
          : 0,
        itemKeys: Object.keys(items),
        items,
      });
    }
  };

  useEffect(() => {
    refresh().then(() => {});
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className=" shadow-md rounded-lg border-2 p-4">
          <div className="text-gray-400">Number of Orders</div>
          <div className="text-lg font-bold text-center">
            {stats ? stats.orderCount : <CircularProgress />}
          </div>
        </div>
        <div className=" shadow-md rounded-lg border-2 p-4">
          <div className="text-gray-400">Number of Items</div>
          <div className="text-lg font-bold text-center">
            {stats ? stats.productCount : <CircularProgress />}
            {stats ? (
              stats.productCount < 50 ? (
                <div className="text-red-500">
                  Please note all custom design orders have a minimum of 40
                  units per unique design
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="grid mt-4 grid-cols-2 gap-4">
        <div className=" shadow-md rounded-lg border-2 p-4">
          <div className="text-gray-400">Gross Sales</div>
          <div className="text-lg font-bold text-center">
            ${stats ? stats.gross.toFixed(2) : <CircularProgress />}
          </div>
        </div>
        <div className="shadow-md rounded-lg border-2 p-4">
          {stats ? (
            stats?.itemKeys.length ? (
              stats.itemKeys.map((key: string, index: number) => (
                <div key={index} className="flex justify-between">
                  <div className="text-gray-400">{key}</div>
                  <div>{stats.items[key]}</div>
                </div>
              ))
            ) : (
              'no orders'
            )
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </>
  );
};

export const Login = () => {
  const [basic, setBasic] = useLocalStorage('expressions_authToken', '');
  const password = useRef<any>();
  const params = useParams();
  const [snack, setSnack] = useState('');
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState<any>(null);

  const refresh = async () => {
    if (basic) {
      const rs = await fetch(`${config.firebase.functions}/getStore`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Expressions-Auth': basic,
        },
        body: JSON.stringify({}),
      }).catch(() => null);

      if (rs) {
        const _store = await rs.json();

        if (_store?.id === params.id) {
          setStore(_store);
        } else {
          setStore(null);
        }
      } else {
        setStore(null);
      }
    }
  };

  const login = async () => {
    setLoading(true);

    if (password.current) {
      const combined = `${params.id}:${
        password.current.querySelector('input').value
      }`;
      const base64 = Base64.encode(combined);

      const rs = await fetch(`${config.firebase.functions}/validateStore`, {
        method: 'POST', // or 'PUT'
        headers: {
          'Expressions-Auth': base64,
        },
        body: JSON.stringify({}),
      });

      const body = await rs.json();

      if (body) {
        setBasic(base64);
      } else {
        setBasic('');
        setSnack('Invalid password');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    refresh().then(() => {});
    // eslint-disable-next-line
  }, [basic]);

  const getToken = (d: any) => {
    const combined = `${d.id}:${d.password}`;
    return Base64.encode(combined);
  };

  const excel = async () => {
    const inv = await fetch(`${config.firebase.functions}/reportxls`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `report-${format(new Date(), 'yyyyLLdd')}.xlsx`);
  };
  const pdf = async () => {
    const inv = await fetch(`${config.firebase.functions}/report`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Expressions-Auth': basic,
        Accept: 'application/pdf',
      },
      body: JSON.stringify({}),
    });

    const blob = await inv.blob();

    saveAs(blob, `report-${format(new Date(), 'yyyyLLdd')}.pdf`);
  };

  return (
    <section className="options mx-6 md:mx-36 my-16 relative">
      <Dots />
      <div className="relative z-10">
        <div style={{ padding: '1rem' }}>
          {basic && store ? (
            <>
              <h1>Manage Store</h1>
              <p>Update your store, and view your orders.</p>
              <Dashboard basic={basic} store={store}></Dashboard>

              {addDays(
                parse(`${store.campaignEndDate}`, 'yyyy-LL-dd', new Date()),
                1
              ) < new Date() ? (
                <div className="mt-6">
                  <h2>Store Closed</h2>
                  <p>
                    Your store has now closed. If you are happy with your final
                    numbers above then click approve. If you want or need to
                    create more orders{' '}
                    <a
                      href={`${window.location.protocol}//${
                        window.location.host
                      }/fundraising-schools/${store.id}?token=${getToken(
                        store
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      click here
                    </a>{' '}
                    to access your store.
                  </p>
                  {store.status === 'approved' ? (
                    <div>
                      <p style={{ fontWeight: 'bold' }}>
                        Thank you for your approval we will be in touch shortly
                        with email confirmation.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        disabled={loading || store.status === 'approved'}
                        className="!mt-4 !mb-4"
                        onClick={async () => {
                          setLoading(true);

                          try {
                            await fetch(
                              `${config.firebase.functions}/approveOrder`,
                              {
                                method: 'POST', // or 'PUT'
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Expressions-Auth': basic,
                                  Accept: 'application/pdf',
                                },
                                body: JSON.stringify({}),
                              }
                            );

                            await sendMail({
                              to: config.adminEmail,
                              email: emails['1Nr15wh8KrTtinTQ2hnF'],
                              merge: {
                                organisationName: store.organisationName,
                                url: `${window.location.protocol}//${window.location.host}/admin/stores/${store.id}/orders`,
                              },
                            });

                            await refresh();

                            toast.success(
                              'Thank you for your approval we will be in touch shortly with email confirmation'
                            );
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}

                  <p>
                    <i>
                      If you need assistance with this please call Expressions
                      on 1300 855 509.
                    </i>
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();

                    window.location.href = `/fundraising-schools/${params.id}/manage/store`;
                  }}
                >
                  Manage Store
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();

                    window.location.href = `/fundraising-schools/${params.id}/manage/orders`;
                  }}
                >
                  View Orders
                </Button>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();

                    await pdf();
                  }}
                >
                  Download PDF Report
                </Button>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();

                    await excel();
                  }}
                >
                  Download Excel Report
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setBasic('');
                  }}
                >
                  Logout
                </Button>
              </div>
            </>
          ) : (
            <>
              <h1>Store Login</h1>
              <p>
                You should have received a password with your activation email.
                Please enter this password below.
              </p>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  await login();
                }}
              >
                <Input ref={password} placeholder="Password" type="password" />
                <Button type="submit" disabled={loading}>
                  Login
                </Button>
              </form>
            </>
          )}
        </div>
      </div>

      <Snackbar
        open={!!snack}
        onClose={() => {
          setSnack('');
        }}
        autoHideDuration={3000}
        message={snack}
      />
    </section>
  );
};
